import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/hooks.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/icons/icons.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/quoted-tweet/quoted-tweet-container.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/theme.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/skeleton.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-actions-copy.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-link.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-info-created-at.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-info.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/quoted-tweet/quoted-tweet-header.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/quoted-tweet/quoted-tweet-body.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-media-video.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/bio/Bio.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/blog-list/BlogList.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-container.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-header.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/verified-badge.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-in-reply-to.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-body.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-media.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-actions.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-replies.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-not-found.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-skeleton.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/twitter/TweetList.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/contacts/Contacts.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/footer/Footer.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/body/body.scss");
